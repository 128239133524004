<template>
  <app-layout-main class="p-4">
    <div
      class="
        container
        px-4
        m-auto
        mx-auto
        md:px-10
        max-w-lg
        space-y-10
        bg-contain bg-app-logo bg-no-repeat bg-center
      "
    >
      <app-logo-swipe />

      <app-card>
        <h3 class="mb-5 font-semibold text-md text-center">
          {{ $t("auth.login.title_header") }}
        </h3>

        <form @submit.prevent="loginForm">
          <div class="space-y-5">
            <app-form-input
              id="email"
              type="email"
              autocomplete="email"
              v-model="user.email"
              :errorMessage="errors.email"
              :labelText="$t('general.email')"
            />
            <app-form-password-input
              id="password"
              type="password"
              v-model="user.password"
              :showForgotPassword="true"
              :errorMessage="errors.password"
              :labelText="$t('general.password')"
            />

            <app-alert-error :message="message" />

            <app-form-checkbox id="remember_me" v-model="user.remember_me">
              {{ $t("auth.login.stay_signed") }}
            </app-form-checkbox>

            <app-button id="sign_in" type="submit" :loading="loading" eventName="login">
              {{ $t("general.sign_in") }}
            </app-button>
          </div>
        </form>
      </app-card>

      <app-link
        id="sign_up"
        :text_before="$t('auth.login.dont_have_account')"
        :to="{ name: 'register' }"
      >
        {{ $t("general.sign_up") }}
      </app-link>
    </div>
  </app-layout-main>
</template>

<script>
export default {
  data() {
    return {
      emptyErrors: {
        email: [],
        password: [],
        message: null,
      },
    };
  },

  computed: {
    message() {
      return this.$store.getters["authStore/message"];
    },

    user() {
      return this.$store.getters["authStore/user"];
    },

    errors() {
      return this.$store.getters["authStore/errors"] ?? this.emptyErrors;
    },
    
    loading() {
      return this.$store.getters["authStore/loading"];
    }
  },

  methods: {
    async loginForm() {
      this.resetState();
      const loginResult = await this.$store.dispatch(
        "authStore/login",
        this.user
      );

      if (loginResult == null) {
        return;
      }

      let redirectTo = this.$router.resolve({ name: "/" }).href;

      if(this.$route.redirectedFrom?.name) {
        redirectTo = this.$router.resolve({ name: this.$route.redirectedFrom.name }).href
      }

      window.location.href = redirectTo;
    },

    resetState() {
      this.$store.commit("authStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>